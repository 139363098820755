 
	// - Variables

		// Breakpoints and MediaQueries shorthands
		@import '../../modules/--Config/_Breakpoints'; 
 
		// Colors

			$black :        #000000;
			$white :        #ffffff;
			$yellow : 	    #F5E600; 
			$pink :  	    #E33B8A;
			$green : 	    #00A174;
			$blue : 	    #00D0CD;
			$gray :		    #8B8B8B;
			$graylight :    #DBDBDB;
			$graylighter :  #F3F3F3;
			$graylightest : #F7F7F7;


			// Dominant color
			$activeColor : $yellow; 

			// List of usable colors 

			$ColorList:(  
				// Color. // Hexa
				'black':	    $black,
				'white':	    $white, 
				'yellow':	    $yellow,
				'pink' : 	    $pink,
				'green':  	    $green, 
				'blue' : 	    $blue, 
				'gray' : 	    $gray,
				'graylight' :   $graylight,
				'graylighter' : $graylighter, 
				'graylightest' : $graylightest
			); 
 		  
 		
		// Typography

			
		

			// Font base - Without unit to be calculated in mixins
			$fontbase : 16; 

			@function rem($px){
				// rem = $px / 16
				$rem : $px / $fontbase;
				@return #{$rem}rem;
			}
			@function em($px){
				// rem = $px / 16
				$rem : $px / $fontbase;
				@return #{$rem}em;
			}

		// 

		$f-sans : 'Flama', Arial, Helvetica, sans-serif!important; 
		$fw-basic : 'Flama Basic','Flama', Arial, Helvetica, sans-serif!important;
		$fw-book : 'Flama Book','Flama', Arial, Helvetica, sans-serif!important;
		//$f-condensed : 'Roboto Condensed', Arial, Helvetica, sans-serif!important;   

		.f-sans{
			font-family: $f-sans;
		} 
		.fw-basic{
			font-family: $fw-basic;
		} 
		.fw-book{
			font-family: $fw-book;
		} 
		
		// .f-condensed{
		// 	font-family: $f-condensed;
		// } 

		$f-main : $f-sans;



			// Weights 
			$fw-thin:100;
			.fw-thin{
				font-weight:$fw-thin;
				font-family:$f-sans;
			}

			$fw-ultralight:200;
			.fw-ultralight{
				font-weight:$fw-ultralight;
				font-family:$f-sans;
			}

			$fw-light:300;
			.fw-light{
				font-weight:$fw-light;
				font-family:$f-sans;
			}

			$fw-medium:500;
			.fw-medium{
				font-weight:$fw-medium;
				font-family:$f-sans;
			}

			$fw-semibold:600;
			.fw-semibold{
				font-weight:$fw-semibold;
				font-family:$f-sans;
			} 

			.fw-bold{
				font-weight:bold;
			}

			$fw-extrabold:800;
			.fw-extrabold{
				font-weight:$fw-extrabold;
				font-family:$f-sans;
			}

			$fw-black:900;
			.fw-black{
				font-weight:$fw-black;
				font-family:$f-sans;
			}

			
 
