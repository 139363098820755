
	// Colors helpers
	// Generating classes to manipulate colors
	
	@import '../../../stylesheets/prod/vars';

	@each $color, $hexa in $ColorList{  
		
		// BorderColor

			.BorderColor-#{$color}{
				border:1px solid #{$hexa};
			}
			
			// - Border thickness
			//   From 1 to 100px
			@for $i from 1 through 100{
			  	.BorderColor-#{$i}-#{$color}{ 
					border:#{$i}px solid #{$hexa}; 
				} 
				.BorderColorTop-#{$i}-#{$color}{ 
					border-top:#{$i}px solid #{$hexa}; 
				} 
				.BorderColorBottom-#{$i}-#{$color}{ 
					border-bottom:#{$i}px solid #{$hexa}; 
				} 
			} // @for
		
		// Text color

			.fc-#{$color}{ 
				color:#{$hexa}!important;
				// Apply color to all children
				// but not if they have a TxtColor
				// on their own
				*:not([class*='TextColor']){
					color:currentColor;
				}
			} // TextColor

		// Background color

			.bg-#{$color}{
				background-color:#{$hexa}; 
			} // BgColor
	 
	} // Loop through each color of $colorlist

